import type {
  BaseCitationDetails,
  CitationDetails,
  MdCitationDetails,
  PDFCitationDetails,
  TxtCitationDetails,
} from "../../component/types";
import type { CitationDetailsDto } from "../dtos/citationDetailsDto";

export const fromDto = (
  citation: CitationDetailsDto,
): CitationDetails | null => {
  const baseCitationLink: BaseCitationDetails = {
    id: citation.id,
    integrationType: citation.integrationType,
    name: citation.name,
    content: citation.content,
  };

  switch (citation.integrationType) {
    case "PDF":
      return pdfFromDto(baseCitationLink, citation);
    case "TEXT":
      return txtFromDto(baseCitationLink, citation);
    case "MD":
      return mdFromDto(baseCitationLink, citation);
    default: {
      // Ignore unsupported citation types for now.
      return null;
    }
  }
};

export const pdfFromDto = (
  baseCitationLink: BaseCitationDetails,
  // TODO add the correct type when BE finish a polishing
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  citation: any,
): PDFCitationDetails => {
  const highlightArea = citation.metadata?.coordinates
    ? {
        start: {
          x: citation.metadata.coordinates.start.x,
          y: citation.metadata.coordinates.start.y,
          pageNumber: citation.metadata.coordinates.start.pageNumber,
        },
        end: {
          x: citation.metadata.coordinates.end.x,
          y: citation.metadata.coordinates.end.y,
          pageNumber: citation.metadata.coordinates.end.pageNumber,
        },
      }
    : undefined;

  return {
    ...baseCitationLink,
    integrationType: "PDF",
    name: citation.name,
    url: citation.preSignedUrl,
    highlightArea,
  };
};

export const txtFromDto = (
  baseCitationLink: BaseCitationDetails,
  // TODO add the correct type when BE finish a polishing
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  citation: any,
): TxtCitationDetails => {
  return {
    ...baseCitationLink,
    integrationType: "TEXT",
    chunk: citation.content,
    url: citation.preSignedUrl,
  };
};

const mdFromDto = (
  baseCitationLink: BaseCitationDetails,
  // TODO add the correct type when BE finish a polishing
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  citation: any,
): MdCitationDetails => {
  return {
    ...baseCitationLink,
    integrationType: "MD",
    url: citation.preSignedUrl,
  };
};
