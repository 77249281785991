import type {
  BaseMessageCitation,
  MessageCitation,
  PdfMessageCitation,
} from "../../component/types";
import type { MessageCitationDto } from "../dtos/messageCitationDto";

export const fromDto = (
  citation: MessageCitationDto,
): MessageCitation | null => {
  const baseMessageCitation: BaseMessageCitation = {
    id: citation.id,
    name: citation.name,
    integrationType: citation.integrationType,
    content: citation.content,
    isFromFile: citation.isAdditionalFetchNeeded,
  };

  switch (citation.integrationType) {
    case "PDF":
      return pdfFromDto(baseMessageCitation);
    case "TEXT":
      return txtFromDto(baseMessageCitation);
    case "MD":
      return mdFromDto(baseMessageCitation);
    case "WEB_SCRAPE":
      return webScrapeFromDto(baseMessageCitation, citation);
    case "NOTION":
      return notionFromDto(baseMessageCitation, citation);
    default: {
      // Ignore unsupported citation types for now.
      return null;
    }
  }
};

const pdfFromDto = (
  baseMessageCitation: BaseMessageCitation,
): PdfMessageCitation => {
  return {
    ...baseMessageCitation,
    integrationType: "PDF",
  };
};

const txtFromDto = (
  baseMessageCitation: BaseMessageCitation,
): MessageCitation => {
  return {
    ...baseMessageCitation,
    integrationType: "TEXT",
  };
};

const mdFromDto = (
  baseMessageCitation: BaseMessageCitation,
): MessageCitation => {
  return {
    ...baseMessageCitation,
    integrationType: "MD",
  };
};

const webScrapeFromDto = (
  baseMessageCitation: BaseMessageCitation,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  citation: any,
): MessageCitation => {
  return {
    ...baseMessageCitation,
    integrationType: "WEB_SCRAPE",
    url: citation.sourceUrl,
  };
};

const notionFromDto = (
  baseMessageCitation: BaseMessageCitation,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  citation: any,
): MessageCitation => {
  return {
    ...baseMessageCitation,
    integrationType: "NOTION",
    url: citation.sourceUrl,
  };
};
