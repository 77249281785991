export class StreamReader {
  private decoder: TextDecoder;
  private reader: ReadableStreamDefaultReader<Uint8Array>;

  constructor(reader: ReadableStreamDefaultReader<Uint8Array>) {
    this.decoder = new TextDecoder("utf-8");
    this.reader = reader;
  }

  async *read() {
    while (true) {
      const { done, value } = await this.reader.read();

      if (done) break;

      const chunk = this.decoder.decode(value);
      const lines = this.processChunk(chunk);

      for (const line of lines) {
        yield line;
      }
    }
  }

  private processChunk(chunk: string): string[] {
    return chunk
      .split("\n\n")
      .map((line) => line.trim())
      .filter((line) => line.length > 0);
  }
}
