import { getFileExtension, getFileName } from "@appsmith/utils";
import { Button, type ButtonProps } from "@appsmith/wds";
import truncate from "lodash/truncate";
import React from "react";

type ChatCitationButtonProps = {
  citationName: string;
  isSelected?: boolean;
} & ButtonProps;

export const ChatCitationButton = ({
  citationName,
  isSelected,
  ...props
}: ChatCitationButtonProps) => {
  /**
   * @example
   * normalizeCitationName("example.pdf") // "example.pdf"
   * normalizeCitationName("very long file name.pdf") // "very long f...pdf"
   */
  const normalizeCitationName = (name: string): string => {
    const fileName = getFileName(name);
    const truncatedFileName = truncate(fileName, { length: 12 });
    const separator = fileName.length === truncatedFileName.length ? "." : "";

    return `${truncatedFileName}${separator}${getFileExtension(name)}`;
  };

  return (
    <Button
      {...props}
      color={isSelected ? "accent" : "neutral"}
      icon="link"
      size="small"
      style={{
        display: "inline-flex",
        verticalAlign: "middle",
      }}
      variant="subtle"
    >
      {normalizeCitationName(citationName)}
    </Button>
  );
};
