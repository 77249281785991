import React from "react";
import { Flex } from "@appsmith/wds";

export const ChatSkeleton = () => {
  return (
    <Flex
      direction="column"
      flex="1"
      gap="spacing-4"
      isInner
      padding="spacing-4"
    >
      {/*TODO: https://github.com/appsmithorg/appsmith/issues/29608*/}
      <Flex className="bp3-skeleton" height="sizing-10" width="100%" />

      <Flex direction="column" flex="1" gap="spacing-2" isInner>
        <Flex className="bp3-skeleton" height="sizing-10" width="80%" />
        <Flex gap="spacing-2">
          <Flex className="bp3-skeleton" height="sizing-10" width="sizing-40" />
          <Flex className="bp3-skeleton" height="sizing-10" width="sizing-40" />
          <Flex className="bp3-skeleton" height="sizing-10" width="sizing-40" />
          <Flex className="bp3-skeleton" height="sizing-10" width="sizing-40" />
        </Flex>
      </Flex>

      <Flex className="bp3-skeleton" height="sizing-20" width="100%" />
    </Flex>
  );
};
