import { Markdown } from "@appsmith/wds";
import React from "react";
import chatStyles from "../../styles.module.css";
import type { MarkdownDocumentViewerProps } from "./types";

export const MarkdownDocumentViewer = (props: MarkdownDocumentViewerProps) => {
  const { document, hasError, ...rest } = props;
  const contentNodeRef = React.useRef<HTMLDivElement>(null);

  const renderContent = () => {
    if (hasError) {
      return <div>{"Can't load the file..."}</div>;
    }

    if (document) {
      return <Markdown ref={contentNodeRef}>{document}</Markdown>;
    }

    return null;
  };

  return (
    <div className={chatStyles.chatDocumentViewer} {...rest}>
      {renderContent()}
    </div>
  );
};
