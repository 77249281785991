import type { ToolCallDto } from "../dtos/toolCallDto";
import type { ToolCall } from "../../component/types/toolCall";

export const fromDto = (dto: ToolCallDto): ToolCall => {
  if (dto.status === "pending") {
    return {
      id: dto.toolCallId,
      entity: {
        id: dto.function.entityId,
        type: dto.function.entityType,
        arguments: dto.function.arguments,
        name: dto.function.name,
        iconLocation: dto.function.iconLocation,
      },
      isApprovalRequired: dto.isApprovalRequired,
      executionStatus: dto.status,
    };
  }

  return {
    id: dto.toolCallId,
    entity: {
      id: dto.function.entityId,
      type: dto.function.entityType,
      arguments: dto.function.arguments,
      name: dto.function.name,
      iconLocation: dto.function.iconLocation,
    },
    output: dto.function.output ? JSON.parse(dto.function.output) : "",
    isApprovalRequired: dto.isApprovalRequired,
    executionStatus: dto.status,
  };
};
