import React from "react";
import { Flex, Text } from "@appsmith/wds";

import { EmptyStateIllustrationCustomIcon } from "appsmith-icons";

export const ChatCitationEmpty = () => {
  return (
    <Flex
      alignItems="center"
      direction="column"
      gap="spacing-3"
      isInner
      padding="spacing-4"
      width="100%"
    >
      <EmptyStateIllustrationCustomIcon />
      <Text fontWeight={600} size="title" textAlign="center">
        Nothing here for now
      </Text>
      <Text
        color="neutral-subtle"
        fontWeight={500}
        textAlign="center"
        wordBreak="break-word"
      >
        Documents and resources that LLM helper references are going to be shown
        here.
      </Text>
    </Flex>
  );
};
