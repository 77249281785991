import { useEffect, useState } from "react";

import { fetchCitation } from "../api";
import type { CitationDetails, MessageCitation } from "./types";
import * as CitationDetailsMapper from "../widget/mappers/citationDetailsMapper";

interface CitationCache {
  citation: CitationDetails;
  expiresAt: number;
}

const citationCache: Record<string, CitationCache> = {};

interface FetchCitationParams {
  queryId: string;
  citationId: string | null;
  messageId: string | null;
  threadId: string;
  viewMode: boolean;
  messageCitation?: MessageCitation;
}

export const useFetchCitation = ({
  citationId,
  messageCitation,
  messageId,
  queryId,
  threadId,
  viewMode,
}: FetchCitationParams) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [citation, setCitation] = useState<CitationDetails | null>(null);

  async function fetchData() {
    if (!citationId || !messageId || !threadId) return;

    // isFromFile is false for citation like web scrape etc,
    // so we don't need to fetch them, we can exit early
    if (!messageCitation?.isFromFile) {
      setCitation(messageCitation as CitationDetails);

      return;
    }

    const cachedCitation = citationCache[citationId];

    if (cachedCitation && Date.now() < cachedCitation.expiresAt) {
      setCitation(cachedCitation.citation);

      return;
    }

    setIsLoading(true);
    setHasError(false);
    setCitation(null);

    try {
      const response = await fetchCitation({
        queryId,
        citationId,
        messageId,
        threadId,
        viewMode,
      });

      if (!response.responseMeta.success) {
        setHasError(true);
        setIsLoading(false);

        return Promise.reject(response.responseMeta.error);
      }

      const citation = CitationDetailsMapper.fromDto(response.data.data);

      if (citation && "preSignedUrl" in response.data.data) {
        const expiresMatch =
          response.data.data.preSignedUrl?.match(/Expires=(\d+)/);

        if (expiresMatch) {
          const expiresAt = parseInt(expiresMatch[1]) * 1000;

          citationCache[citationId] = { citation, expiresAt };
        }

        setCitation(citation);
      }
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(
    function handleCitationChange() {
      fetchData();
    },
    [citationId, threadId, messageId],
  );

  return {
    isLoading,
    hasError,
    citation,
    retry: fetchData,
  };
};
