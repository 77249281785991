import { Button, Flex, Text, useSidebar } from "@appsmith/wds";
import clsx from "classnames";
import React, { memo, useState, type ReactNode } from "react";
import type { ToolCall } from "../types/toolCall";
import styles from "./styles.module.css";
import { getAssetUrl } from "../../../../../../../utils/airgapHelpers";

interface ChatMessageToolCallItemProps {
  messageId: string;
  toolCall: ToolCall;
  onApprove: (messageId: string, toolCallId: string) => void;
  onSelect: (toolCall: ToolCall) => void;
}

export const ChatMessageToolCallItem = memo(
  ({
    messageId,
    onApprove,
    onSelect,
    toolCall,
  }: ChatMessageToolCallItemProps) => {
    const [isArgumentsOpen, setIsArgumentsOpen] = useState(false);
    const { setState } = useSidebar();
    const { entity, executionStatus, id } = toolCall;

    const resolveStatusLabel = (): string | null => {
      switch (executionStatus) {
        case "inProgress":
          return "Executing";
        case "pendingApproval":
          return "Requires Approval";
        case "success":
          return "Success";
        case "error":
          return "Error";
        default:
          return null;
      }
    };

    const resolveStatusDescription = (): string | null => {
      switch (executionStatus) {
        case "pendingApproval":
          return "Your approval is required for this function.";
        case "success":
          return "Successfully executed function.";
        case "error":
          return "There was an unexpected error.";
        default:
          return null;
      }
    };

    const resolveActionButton = (): ReactNode | null => {
      switch (executionStatus) {
        case "pendingApproval":
          return (
            <Button
              color="neutral"
              onPress={() => onApprove(messageId, id)}
              variant="filled"
            >
              Approve
            </Button>
          );
        case "success":
          return (
            <Button
              color="neutral"
              onPress={() => {
                onSelect(toolCall);
                setState("expanded");
              }}
              variant="filled"
            >
              View response
            </Button>
          );
        case "error":
          return (
            <Button
              color="neutral"
              onPress={() => {
                onSelect(toolCall);
                setState("expanded");
              }}
              variant="filled"
            >
              View response
            </Button>
          );
        default:
          return null;
      }
    };

    const statusDescription = resolveStatusDescription();

    return (
      <div className={styles.chatMessageToolCallItem}>
        <Flex alignItems="center" gap="spacing-2">
          {toolCall.entity.iconLocation && (
            <div className={styles.icon}>
              <img alt="" src={getAssetUrl(toolCall.entity.iconLocation)} />
            </div>
          )}
          <Text fontWeight={500}>{entity.name}</Text>
          <div
            className={clsx(styles.status, {
              [styles.statusInProgress]: executionStatus === "inProgress",
              [styles.statusPendingApproval]:
                executionStatus === "pendingApproval",
              [styles.statusSuccess]: executionStatus === "success",
              [styles.statusError]: executionStatus === "error",
            })}
          >
            {resolveStatusLabel()}
          </div>
        </Flex>

        {entity.arguments && (
          <div className={styles.arguments}>
            <Button
              color="neutral"
              icon={isArgumentsOpen ? "chevron-down" : "chevron-right"}
              onPress={() => setIsArgumentsOpen((prev) => !prev)}
              variant="ghost"
            >
              Arguments
            </Button>
            {isArgumentsOpen && (
              <pre className={styles.argumentsJson}>
                {JSON.stringify(JSON.parse(entity.arguments), null, 2)}
              </pre>
            )}
          </div>
        )}

        {statusDescription && (
          <div
            className={clsx(styles.statusDetails, {
              [styles.statusDetailsSuccess]: executionStatus === "success",
              [styles.statusDetailsError]: executionStatus === "error",
              [styles.statusDetailsPendingApproval]:
                executionStatus === "pendingApproval",
            })}
          >
            <Text
              className={clsx(styles.statusDescription, {
                [styles.statusDescriptionPendingApproval]:
                  executionStatus === "pendingApproval",
                [styles.statusDescriptionSuccess]:
                  executionStatus === "success",
                [styles.statusDescriptionError]: executionStatus === "error",
              })}
              size="body"
            >
              {statusDescription}
            </Text>

            {resolveActionButton()}
          </div>
        )}
      </div>
    );
  },
);
