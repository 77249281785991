export class StreamLineParser<T> {
  parse(line: string): T | null {
    const [eventLine, dataLine] = line.split("\n");

    if (!eventLine || !dataLine) return null;

    const event = eventLine.replace("event:", "");
    const data = dataLine.replace("data:", "");

    if (!event || !data) return null;

    try {
      const parsedData = JSON.parse(data);

      return {
        event,
        data: parsedData,
      } as T;
    } catch (e) {
      return null;
    }
  }
}
