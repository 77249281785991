import type {
  AssistantActionRequestMessage,
  AssistantTextMessage,
  Message,
  UserMessage,
} from "../../component/types";
import type { MessageDto } from "../dtos/messageDto";
import * as MessageCitationMapper from "./messageCitationMapper";
import * as ToolCallsMapper from "./toolCallsMapper";

export const fromDto = (message: MessageDto): Message => {
  if (message.role === "assistant") {
    if (message.type === "text") {
      const citations =
        message.citations
          ?.map(MessageCitationMapper.fromDto)
          .filter((citation) => citation !== null) || [];

      return {
        role: message.role,
        type: message.type,
        id: message.id,
        content: message.message[0].text.value,
        citations,
        createdAt: message.createdAt,
      } satisfies AssistantTextMessage;
    }

    if (message.type === "action_request") {
      return {
        role: message.role,
        type: message.type,
        id: message.id,
        content: message.message.map(ToolCallsMapper.fromDto),
        runId: message.runId,
        threadId: message.threadId,
        outputSubmissionStatus: message.outputSubmissionStatus,
        createdAt: message.createdAt,
      } satisfies AssistantActionRequestMessage;
    }
  }

  return {
    role: message.role,
    type: message.type,
    id: message.id,
    content: message.message[0].text.value,
    createdAt: message.createdAt,
  } satisfies UserMessage;
};
