import React, { useEffect, useRef, useState } from "react";
import { useSidebar } from "@appsmith/wds";
import { Button, Text } from "@appsmith/wds";

import styles from "./styles.module.css";
import chatStyles from "../../styles.module.css";

interface ChatWebPageViewerProps {
  url?: string;
}

export function ChatWebPageViewer(props: ChatWebPageViewerProps) {
  const { url } = props;
  const { setState } = useSidebar();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [hasError, setHasError] = useState(false);

  const fetchPage = async () => {
    if (!url) return;

    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error("Failed to load the webpage");
      }

      if (iframeRef.current) {
        iframeRef.current.src = url;
      }
    } catch (error) {
      setHasError(true);
    }
  };

  useEffect(
    function handleUrlChange() {
      if (!url) return;

      setState("expanded");
      fetchPage();
    },
    [url],
  );

  if (!url) return null;

  const handleRetry = () => {
    setHasError(false);
    fetchPage();
  };

  if (hasError) {
    return (
      <div className={chatStyles.documentViewer}>
        <div className={chatStyles.documentViewerError}>
          <Text>Can&apos;t load the document...</Text>
          <Button
            color="neutral"
            onPress={handleRetry}
            size="small"
            variant="subtle"
          >
            Retry
          </Button>
        </div>
      </div>
    );
  }

  return <iframe className={styles.webPageViewerIframe} ref={iframeRef} />;
}
