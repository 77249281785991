import clamp from "lodash/clamp";
import round from "lodash/round";
import type { VariableSizeList as List } from "react-window";

import type { PdfCitationHighlightAnchorCoordinate } from "../../types";

const MIN_ZOOM_SCALE = 1;
const MAX_ZOOM_SCALE = 3;

/**
 * react-pdf generates dom elements for each page with class .react-pdf__Page.
 * and we can accesss a specific page by using the data-page-number attribute. (e.g. .react-pdf__Page[data-page-number='1'])
 * So, to find the citation highlight on a specific page, we can use the following selector:
 * .react-pdf__Page[data-page-number='${pageNumber}'] .${highlightSelector}
 *
 * @param pageNumber
 * @param highlightSelector
 * @returns
 */
export const findCitationHighlight = (
  pageNumber: number,
  highlightSelector: string,
) => {
  return (
    document
      .querySelector(`.react-pdf__Page[data-page-number='${pageNumber}']`)
      ?.querySelector(`.${highlightSelector}`) || null
  );
};

export const scrollToCitation = (
  pageNumber: number,
  highlightSelector: string,
  listRef: React.RefObject<List>,
  documentNodeRef: React.RefObject<HTMLDivElement>,
): void => {
  const highlightElement = findCitationHighlight(pageNumber, highlightSelector);

  if (highlightElement && documentNodeRef.current) {
    highlightElement.scrollIntoView({ block: "start" });

    return;
  }
};

export const getPageScale = (
  containerWidth: number,
  start: PdfCitationHighlightAnchorCoordinate,
  end: PdfCitationHighlightAnchorCoordinate,
) => {
  const citationWidth = (containerWidth / 100) * ((end.x - start.x) * 100);
  const scale = containerWidth / citationWidth;

  return normalizePageScale(scale);
};

export const isSinglePageCitation = (
  start: PdfCitationHighlightAnchorCoordinate,
  end: PdfCitationHighlightAnchorCoordinate,
) => start.pageNumber === end.pageNumber;

export const shouldShowCitationHighlight = (
  pageNumber: number,
  start: PdfCitationHighlightAnchorCoordinate,
  end: PdfCitationHighlightAnchorCoordinate,
) => {
  return pageNumber >= start.pageNumber && pageNumber <= end.pageNumber;
};

interface ResolveCitationHighlightStyleProps {
  end: PdfCitationHighlightAnchorCoordinate;
  start: PdfCitationHighlightAnchorCoordinate;
  pageNumber: number;
}

export const resolveCitationHighlightStyle = (
  props: ResolveCitationHighlightStyleProps,
) => {
  const { end, pageNumber, start } = props;

  const left = start.x;
  const width = end.x - start.x;
  let top = 0;
  let height = 0;

  if (isSinglePageCitation(start, end)) {
    top = start.y;
    height = end.y - start.y;
  } else {
    // Stretch the highlight to the bottom of the first page
    if (pageNumber === start.pageNumber) {
      top = start.y;
      height = 1 - start.y;
    }
    // Cover intermediate pages entirely
    else if (pageNumber < end.pageNumber) {
      top = 0;
      height = 1;
    }
    // Stretch the highlight to the top of the last page
    else {
      top = 0;
      height = end.y;
    }
  }

  return {
    left: `${left * 100}%`,
    top: `${top * 100}%`,
    width: `${width * 100}%`,
    height: `${height * 100}%`,
  };
};

export const normalizePageScale = (scale: number): number => {
  return round(clamp(scale, MIN_ZOOM_SCALE, MAX_ZOOM_SCALE), 1);
};
