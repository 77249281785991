import { ToolbarButtons } from "@appsmith/wds";
import React from "react";
import styles from "../styles.module.css";

interface ZoomControlsProps {
  onZoomIn: () => void;
  onZoomOut: () => void;
}

export const ZoomControls = ({ onZoomIn, onZoomOut }: ZoomControlsProps) => {
  return (
    <div className={styles.zoomButtons}>
      <ToolbarButtons
        density="compact"
        items={[
          {
            id: "zoom-in",
            icon: "zoom-in",
          },
          {
            id: "zoom-out",
            icon: "zoom-out",
          },
        ]}
        onAction={(id) => {
          if (id === "zoom-in") onZoomIn();

          if (id === "zoom-out") onZoomOut();
        }}
        size="small"
        variant="subtle"
      />
    </div>
  );
};
